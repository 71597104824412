.site-dev, .site-pro {
    .site-bg, .navbar {
        background: -webkit-linear-gradient(330deg, #6A4EE1 0, #05BDD7 100%) !important;
        background: -o-linear-gradient(330deg, #6A4EE1 0, #05BDD7 100%) !important;
        background: linear-gradient(120deg, #6A4EE1 0, #05BDD7 100%) !important;
    }
}


.site-bla {
    .site-bg, .navbar {
        background-color: #F3E9E1;

        h1, h2, h3, h4, h5, h6, p {
            color: #000000;
        }
    }


}

.site-rin {
    .site-bg, .navbar {
        background-color: #0E3762 !important;

        h1, h2, h3, h4, h5, h6 {
            color: #FFFFFF;
        }
    }
}

.site-ma, .site-mq {
    .site-bg, .navbar {
        background-color: #1E2412 !important;

        h1, h2, h3, h4, h5, h6 {
            color: #FFFFFF;
        }
    }
}

.site-dbg {
    .site-bg, .navbar {
        background-color: #57951A !important;

        h1, h2, h3, h4, h5, h6 {
            color: #FFFFFF;
        }
    }
}

.site-blq {
    .site-bg, .navbar {
        background-color: #212121 !important;

        h1, h2, h3, h4, h5, h6 {
            color: #FFFFFF;
        }
    }
}

.site-bir {

    .site-bg, .navbar {
        background-color: #BCBEC0 !important;

        h1, h2, h3, h4, h5, h6 {
            color: #000000;
        }
    }
}

.site-web, .site-wef, .site-weg, .site-wei, .site-pir {
    .site-bg, .navbar {
        background-color: #3E4F60 !important;

        h1, h2, h3, h4, h5, h6 {
            color: #FFFFFF;
        }
    }
}


