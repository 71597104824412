/*----------------------------------------
  26. Progress bar
----------------------------------------*/

.progress {
	height: 6px;
	background-color: #ececec;
	border-radius: 50px;
	margin-bottom: 20px;

	&.progress-sm {
		height: 4px;		
	}

	&.progress-lg {
		height: 12px;	
		border-radius: 4px;	

		.progress-bar {
			&:last-child {
				border-top-right-radius: 4px;
				border-bottom-right-radius: 4px;
			}
		}
	}

	&.progress-xl {
		height: 20px;
		border-radius: 4px;	
		
		.progress-bar {
			&:last-child {
				border-top-right-radius: 4px;
				border-bottom-right-radius: 4px;
			}
		}
	}
}

.progress-bar {
	background-color: $default-success;

	&:last-child {
		border-top-right-radius: 50px;
		border-bottom-right-radius: 50px;
	}
}
