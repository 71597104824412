/*----------------------------------------
  46. Sparkline
----------------------------------------*/

.sparkline-chart {
	overflow: hidden;
}

.jqstooltip {
    box-sizing: content-box;
}
