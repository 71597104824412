/*----------------------------------------
  33. Controls
----------------------------------------*/

.checkbox {
	padding: 10px 0;
	min-height: auto;
	position: relative;
	
	input[type=checkbox] {
		margin: 0;
		display: none;
		width: 22px;
		
		+label {
			padding-left: 0px;
			
			&:before {
				content: "\f00c";
				width: 22px;
				height: 22px;
				display: inline-block;
				margin-right: 10px;
				border: 2px solid $border-color;
				border-radius: 3px;
				font-size: 15px;
				font-family: 'Font Awesome 5 Pro';
				font-weight: 400;
				line-height: 19px;
				vertical-align: bottom;
				text-align: center;
				background: $default-white;
				color: transparent;
				cursor: pointer;
				@include transition(all 0.2s ease-in-out); 
			}
		}
		
		&:checked {
			
			+label {
				&:before {
					background: $default-success;
					border-color: $default-success;
					color: $default-white;
				}
			}
		}

		&:disabled,
		&.disabled {
			
			+label {
				opacity: 0.5;

				&:before {
					cursor: not-allowed;
				}
			}
		}

		&.error {

			+label {
				
				&:before {
					border-color: #ffd0d9;
				}
			}
		}
	}

	&.to-do {
		input[type=checkbox] {

			&:checked {
			
				+label {
					text-decoration: line-through;
				}
			}
		}
	}

	&.checkbox-primary {
		input[type=checkbox] {
			&:checked {
			
				+label {
					&:before {
						background: $default-primary;
						border-color: $default-primary;
						color: $default-white;
					}
				}
			}
		}	
	}

	&.checkbox-info {
		input[type=checkbox] {
			&:checked {
			
				+label {
					&:before {
						background: $default-info;
						border-color: $default-info;
						color: $default-white;
					}
				}
			}
		}	
	}

	&.checkbox-warning {
		input[type=checkbox] {
			&:checked {
			
				+label {
					&:before {
						background: $default-warning;
						border-color: $default-warning;
						color: $default-white;
					}
				}
			}
		}	
	}

	&.checkbox-danger {
		input[type=checkbox] {
			&:checked {
			
				+label {
					&:before {
						background: $default-danger;
						border-color: $default-danger;
						color: $default-white;
					}
				}
			}
		}	
	}
}

.radio {
	padding: 10px 0;
	min-height: auto;
	position: relative;
	
	input[type=radio] {
		margin: 0;
		display: none;
		width: 22px;
		
		+label {
			padding-left: 0;
			
			&:before {
				content: "\f111";
				width: 22px;
				height: 22px;
				display: inline-block;
				margin-right: 10px;
				border: 2px solid $border-color;
				border-radius: 50%;
				font-size: 11px;
                font-family: 'Font Awesome 5 Pro';
				font-weight: 400;
				line-height: 18px;
				vertical-align: bottom;
				text-align: center;
				background-color: $default-white;
				color: transparent;
				cursor: pointer;
				@include transition(all 0.2s ease-in-out); 
			}
		}
		
		&:checked {
			
			+label {
				&:before {
					color: $default-success;
					border-color: $default-success;
				}
			}
		}

		&:disabled,
		&.disabled {
			
			+label {
				opacity: 0.5;

				&:before {
					cursor: not-allowed;
				}
			}
		}
	}

	&.radio-primary {
		input[type=radio] {
			&:checked {
			
				+label {
					&:before {
						color: $default-primary;
						border-color: $default-primary;
					}
				}
			}
		}	
	}

	&.radio-info {
		input[type=radio] {
			&:checked {
			
				+label {
					&:before {
						color: $default-info;
						border-color: $default-info;
					}
				}
			}
		}	
	}

	&.radio-warning {
		input[type=radio] {
			&:checked {
			
				+label {
					&:before {
						color: $default-warning;
						border-color: $default-warning;
					}
				}
			}
		}	
	}

	&.radio-danger {
		input[type=radio] {
			&:checked {
			
				+label {
					&:before {
						color: $default-danger;
						border-color: $default-danger;
					}
				}
			}
		}	
	}
}

.radio,  
.checkbox {
	label{
		cursor: pointer;
	}
}	

.switch {
	input[type=checkbox] {
		opacity: 0;
		position: absolute;

		+label {
			position: relative;
		    display: inline-block;
		    @include transition(.4s ease);
		    height: 20px;
		    width: 35px;
		    border: 1px solid $border-color;
		    border-radius: 60px;
		    cursor: pointer;

		    &:before {
		    	content: "";
				position: absolute;
		        display: block;
				@include transition(0.2s cubic-bezier(0.24, 0, 0.5, 1));
				height: 20px;
				width: 35px;
				top: 0;
				left: 0;
				border-radius: 30px;
		    }

		    &:after {
		    	content: "";
				position: absolute;
				display: block;
				box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0px 0 rgba(0, 0, 0, 0.04), 0 4px 9px rgba(0, 0, 0, 0.13), 0 3px 3px rgba(0, 0, 0, 0.05);
				@include transition(0.35s cubic-bezier(0.54, 1.6, 0.5, 1));
				background: darken($default-white,3%);
				height: 19px;
				width: 19px;
				top: 0px;
				left: 0px;
				border-radius: 60px;
		    }
		}

		&:checked {

			+label {

				&:before {
					background: $default-success;
					@include transition(width 0.2s cubic-bezier(0, 0, 0, 0.1));
				}

				&:after {
					left: 16px;
				}
			}
		}

		&:disabled {
			+label {
				opacity: 0.7;
				cursor: not-allowed;
			}
		}
	}

	&.switch-primary {

		input[type=checkbox] {
			&:checked {

  				+label {

  					&:before {
  						background: $default-primary;
  					}
  				}
  			}
		}	
	}

	&.switch-info {

		input[type=checkbox] {
			&:checked {

  				+label {

  					&:before {
  						background: $default-info;
  					}
  				}
  			}
		}	
	}

	&.switch-warning {

		input[type=checkbox] {
			&:checked {

  				+label {

  					&:before {
  						background: $default-warning;
  					}
  				}
  			}
		}	
	}

	&.switch-danger {

		input[type=checkbox] {
			&:checked {

  				+label {

  					&:before {
  						background: $default-danger;
  					}
  				}
  			}
		}	
	}
}

.color-selector {
	@extend.theme-selector
}