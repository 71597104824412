/*----------------------------------------
  14. Modal
----------------------------------------*/

.modal {
	.modal-dialog {
		@include transition(all 0.2s ease-out); 
	}
	
	&:not(.modal-right),
	&:not(.modal-left),
	&:not(.modal-fs) {
		.modal-dialog {
			@include transform(translateY(20px));	
		}

		&.show {
			.modal-dialog {
				@include transform(translateY(0%));
			}
			
		}
	}

	&.modal-right {
		padding-right: 0px !important;

		.modal-dialog {
			@include transform(translateX(100%));	
		}
		
		&.show {
			.modal-dialog {
				@include transform(translateX(0%));
			}
			
		}
	}
	
	&.modal-left {
		padding-right: 0px !important;

		.modal-dialog {
			@include transform(translateX(-100%));	
		}
		
		&.show {
			.modal-dialog {
				@include transform(translateX(0%));
			}
			
		}
	}
	
	&.modal-fs {
		padding-right: 0px !important;

		.modal-dialog {
			@include transform(scale(0.7));
		}
		
		&.show {
			.modal-dialog {
				@include transform(scale(1));
			}
		}
	}
}

.modal-header {
	padding: 15px 20px;
	padding-bottom: 5px;
    border-bottom: 0px;
	
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-bottom: 0px;
	}
}

.modal-body {
	padding: 15px 20px;
}

.modal-footer {
	padding: 15px 20px;
	border-top: 1px solid $border-color;
	text-align: left;
	
	.btn {
		margin-bottom: 0px;
		
		+.btn {
			margin-left: 0px;
		}
	}
}

.modal-content {
    position: relative;
    background-color: $default-white;
    border: 0px;
    border-radius: 0px;
	outline: 0;
	@include box-shadow(0, 16px, 24px, rgba(43, 47, 49, 0.25)); 
}

.modal-right,
.modal-left {

	.modal-dialog {
		width: 400px;
		height: 100%;
		margin: 0px auto 0px 0px ;
		
		@include screen-mobile {
			width: calc(100% - 50px);
		} 
		
		.modal-content {
			height: 100%;
			border-radius: 0px;
			
			.side-modal-wrapper {
				height: 100%;
				position: relative;
			}
			
			.modal-footer {
				position: absolute;
				bottom: 0px;
				width: 100%;
			}
		}
	}
}

.modal-right {
	.modal-dialog {
		margin: 0px 0px 0px auto;
	}
}

.modal-backdrop {
	z-index: 1040;
	background-color: $default-dark;
}

.modal-fs {
	.modal-dialog {
		width: 100%;
		margin: 0px auto;
		height: 100%;
		max-width: none;
		
		.modal-content {
			height: 100%;
			border-radius: 0px;
			@include background-opacity($default-white, 0.95); 
		}
	}
	
	.modal-close {
		position: absolute;
		top: 20px;
		right: 20px;
		padding: 7px 10px;
		border: 1px solid lighten($default-dark, 30%);
		border-radius: 50px;
		color: lighten($default-dark, 30%);
		
		&:hover,
		&:focus {
			color: $default-dark;
			text-decoration: none;
			border: 1px solid $default-dark;
		} 
	}
}

