.card-header {
    .card-sub-title {
        ul {
            font-size: 12px;
            margin:    3px 0;
            padding:   0;

            li {
                list-style:    none;
                float:         left;
                padding-right: 10px;
                margin-right:  10px;
            }
        }
    }
}

/*
.page-container {
  .main-content {
	padding: 35px 15px 15px 15px !important;
  }
}
*/
.float-right {
    float:    right;
    position: relative;
}

#bth-staff-notes {
    position: fixed;
    bottom:   0;
    right:    10px;
}

.modal-staff-notes {
    width:     700px !important;
    max-width: 700px !important;

    .modal-body {
        overflow: hidden;
    }

    .notes-container {
        height:     100vh;
        position:   relative;
        overflow-y: scroll;
    }
}


.img-header {
    max-height: 40px;
}

.item-warning {
    position:         relative;
    padding:          0.75rem 1.25rem;
    margin-bottom:    1rem;
    border:           1px solid #FEDF7B !important;
    // padding:          15px 20px;
    border-radius:    0 !important;;
    color:            #FECD2F !important;
    background-color: #FFFEFA !important;
    padding-left:     0 !important;;
    padding-right:    0 !important;;
}


.ul-left {
    ul {
        padding-left: 0 !important;
    }
}


// Google Maps Modal issue.. this should fix it!
.pac-container {
    z-index: 1060 !important;
}

.challenge {
    visibility: hidden;
}