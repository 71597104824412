/*----------------------------------------
  12. Alert
----------------------------------------*/

.alert {
    padding: 15px 20px; 
    border-radius: 0px;
}

.alert-default {
    color: darken($default-text-color, 5%);
    background-color: lighten($border-color, 3%);
    border-color: darken($border-color, 6%);

    p {
        color: darken($default-text-color, 5%);
    }

    hr {
        border-color: $border-color;
    }
}

.alert-primary {
    color: $default-primary;
    background-color: lighten($default-primary, 33%);
    border-color: lighten($default-primary, 25%);

    p {
        color: $default-primary; 
    }

    hr {
        border-color: lighten($default-primary, 25%);
    } 
}

.alert-success {
    color: $default-success;
    background-color: lighten($default-success, 45%);
    border-color: lighten($default-success, 25%);

    p {
        color: $default-success; 
    }

    hr {
        border-color: lighten($default-success, 25%);
    } 
}

.alert-info {
    color: $default-info;
    background-color: lighten($default-info, 47%);
    border-color: lighten($default-info, 30%);

    p {
        color: $default-info; 
    }

    hr {
        border-color: lighten($default-info, 30%);
    } 
}

.alert-warning {
    color: $default-warning;
    background-color: lighten($default-warning, 40%);
    border-color: lighten($default-warning, 15%);

    p {
        color: $default-warning; 
    }

    hr {
        border-color: lighten($default-warning, 15%);
    } 
}

.alert-danger {
    color: $default-danger;
    background-color: lighten($default-danger, 39%);
    border-color: lighten($default-danger, 30%);

    p {
        color: $default-danger; 
    }

    hr {
        border-color: lighten($default-danger, 30%);
    } 
}

.alert-primary-gradient {
    color: $default-white;
    border-color: transparent;
    background: $gradient-primary;
    border-radius: 4px;
}

.alert-success-gradient {
    color: $default-white;
    border-color: transparent;
    background: $gradient-success;
    border-radius: 4px;
}

.alert-info-gradient {
    color: $default-white;
    border-color: transparent;
    background: $gradient-info;
    border-radius: 4px;
}

.alert-warning-gradient {
    color: $default-white;
    border-color: transparent;
    background: $gradient-warning;
    border-radius: 4px;
}

.alert-danger-gradient {
    color: $default-white;
    border-color: transparent;
    background: $gradient-danger;
    border-radius: 4px;
}

.alert-float {
    border-color: transparent;
    @include box-shadow(0px, 7px, 20px, 2px rgba(9, 4, 25, 0.10)); 
}

.alert-icon {
    font-size: 30px;
    padding: 0px 7px;
}
