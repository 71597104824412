/*----------------------------------------
  28. Tooltips
----------------------------------------*/

$tooltip-bg: #212224;

.tooltip-inner {
    background-color: $tooltip-bg;
    border-radius: 0px;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: $tooltip-bg;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
    border-right-color: $tooltip-bg;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: $tooltip-bg;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
    border-left-color: $tooltip-bg;
}