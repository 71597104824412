/*----------------------------------------
  8. Page Container
----------------------------------------*/

.page-container {
	min-height: 100vh;
	padding-left: $offscreen-size;

  	&.no-page-padding {
	  padding-left: 0;
	}

	@include transition(all 0.2s ease); 
	@include print {
		padding-left: 0px;
	}

	@include screen-tablet {
		padding-left: 0px;;
	} 

	.main-content {
		padding: calc(#{$header-height} + 35px) 15px 15px 15px;
		min-height: calc(100vh - #{$header-height});

		&.full-container {
			padding: 0px;
			padding-top: 95px;
		}

		@include print {
			padding: 10px 0px;
		}

		@include screen-tablet {
			padding: 85px 10px 10px 10px;
		}	
	}

	.page-header {
		margin-bottom: 30px;

		.header-title {
			display: inline-block;
			font-size: 23px;
			margin-bottom: 0px;
			padding-bottom: 5px;
			border-right: 1px solid $border-color;
			padding-right: 20px;
			line-height: 1; 

			@include screen-mobile {
				border-right: 0px;
				display: block;
				margin-bottom: 15px;	
			} 
		}

		.header-sub-title {
			display: inline-block;
			padding-left: 20px;

			@include screen-mobile {
				padding-left: 0px;
				display: block;	
			} 
		}
	}
}

.side-nav-folded {

	@include screen-tablet-above {
		.page-container {
			padding-left: $collapsed-size;
		}
	}	

	@include screen-tablet {
		.page-container {
			padding-left: 0px;
		}
	}
}
