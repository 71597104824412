.contact-item {

	.contact-thumb {
		margin-bottom: 20px;

		> img {
			border-radius: 50px;
			width: 100px;
			height: 100px;
		}
	}
}