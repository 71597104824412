/*----------------------------------------
  21. Breadcrumb
----------------------------------------*/

.breadcrumb {
	padding: 0px;
    margin-bottom: 0px;
    list-style: none;
    background-color: transparent;
    border-radius: 0px;
    font-size: 13px;
    display: inline-block;
    vertical-align: text-bottom;

    a {
        &.breadcrumb-item {
            color: $default-text-color;


            &:hover,
            &:focus {
                color: $default-dark;
            }
        }
    }

    &.breadcrumb-dash {

        .breadcrumb-item {
            + .breadcrumb-item {
                &:before {
                    content: "-";
                }
            }
        }
    }

    .breadcrumb-item {
        &.active {
            color: $default-dark;
        }
    }
}
