/*----------------------------------------
  27. Popovers
----------------------------------------*/

.popover {
	border-radius: 0px;
	border: 1px solid $border-color;
	@include box-shadow(0, 9px, 12px, rgba(0, 0, 0, 0.075)); 
}

.popover-header {
	background-color: $default-gray;
	color: $default-dark;
	border-bottom: 1px solid $border-color;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.popover-body {
	padding: 10px 15px;
	color: $default-dark;
}

.bs-popover-right .arrow::before,
.bs-popover-auto[x-placement^="right"] .arrow::before {
	border-right-color: $border-color;
}

.bs-popover-top .arrow::before,
.bs-popover-auto[x-placement^="top"] .arrow::before {
	border-top-color: $border-color;
}

.bs-popover-left .arrow::before,
.bs-popover-auto[x-placement^="left"] .arrow::before {
	border-left-color: $border-color;
}

.bs-popover-bottom .arrow::before,
.bs-popover-auto[x-placement^="bottom"] .arrow::before {
	border-bottom-color: $border-color;
}