/*----------------------------------------
  4. Aside
----------------------------------------*/

.aside {
	background-color: $default-white;
	position: fixed;
    top: 0;
	bottom: 0;
	left: $offscreen-size;
	width: $aside-size;
	border-right: 1px solid $border-color;
	padding-top: $header-height;
	@include transition(all 0.3s ease-in-out); 
	z-index: 1000;

	@include screen-mobile {
		left: -$aside-size;

		&.open {
			left: 0px;
		}
	}

	.aside-wrapper {
		padding: 25px 20px;
		height: 100%;
	}

	&~.main-content,
	&~.content-footer {
		@include screen-mobile-above {
			margin-left: $aside-size;
		}
	}

	.aside-toggle {
		display: none;
		position: absolute;
		top: 50%;
		cursor: pointer;
		background: $default-white;
		padding: 15px 8px 15px 4px;
		border-radius: 0 100px 100px 0;
		border: 1px solid $border-color;
		right: -28px;
		z-index: 1000;

		@include screen-mobile {
			display: block;
		}

		.chevron-left {
			display: none;
		}
	}

	&.open {
		.aside-toggle {
			.chevron-left {
				display: block;
			}

			.chevron-right {
				display: none;
			}
		}	
	}
}