/*----------------------------------------
  35. Wizard
----------------------------------------*/

.wizard {
	display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: baseline;
    align-items: baseline;
    margin-bottom: 1rem;

	.nav-item {
		position: relative;
		display: -webkit-box;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		flex-direction: column;
		-webkit-box-flex: 1;
		flex: 1 1 0%;
		padding: 0 12px;
		-webkit-box-align: center;
		align-items: center;

		> .nav-link {
			display: -webkit-inline-box;
			display: inline-flex;
			margin: 10px 0;
			padding: 0px;
			width: 20px;
			height: 20px;
			z-index: 1;

			&:after {
				content:"";
				position: absolute;
				width: 20px;
				height: 20px;
				background: $border-color;
				border-radius: 50px;
				@include transition(all 0.4s ease-in-out); 
			}

			&:before {
				content: '';
				position: absolute;
				left: calc(-50% + 10px);
				right: calc(50% + 9.5px);
				height: 3px;
				margin-top: 10px;
				background: $border-color;
				cursor: default;
				@include transition(all 0.4s ease-in-out); 
			}

			&.active {
				&:after {
					background: $default-white; 
					border: 3px solid $default-success;
				}

				&:before {
					background: $default-success;
				}
			}

			&.completed {
				&:after {
					background: $default-success;
				}	

				&:before {
					background: $default-success;
				}
			}
		}

		.nav-title {
			margin-top: 10px;
			font-size: 18px;
			font-weight: 300;
		}

		&:first-child {
			a {
				&:before {
					content: none;
				}
			}
		}
	}

	&.wizard-primary {
		.nav-item {
			> .nav-link {
				&.active {
					&:after {
						border: 3px solid $default-primary;
					}
	
					&:before {
						background: $default-primary;
					}
				}
	
				&.completed {
					&:after {
						background: $default-primary;
					}
					
					&:before {
						background: $default-primary;
					}
				}
			}	
		}
	}

	&.wizard-info {
		.nav-item {
			> .nav-link {
				&.active {
					&:after {
						border: 3px solid $default-info;
					}
	
					&:before {
						background: $default-info;
					}
				}
	
				&.completed {
					&:after {
						background: $default-info;
					}	

					&:before {
						background: $default-info;
					}
				}
			}	
		}
	}

	&.wizard-warning {
		.nav-item {
			> .nav-link {
				&.active {
					&:after {
						border: 3px solid $default-warning;
					}
	
					&:before {
						background: $default-warning;
					}
				}
	
				&.completed {
					&:after {
						background: $default-warning;
					}	

					&:before {
						background: $default-warning;
					}
				}
			}	
		}
	}

	&.wizard-danger {
		.nav-item {
			> .nav-link {
				&.active {
					&:after {
						border: 3px solid $default-danger;
					}
	
					&:before {
						background: $default-danger;
					}
				}
	
				&.completed {
					&:after {
						background: $default-danger;
					}	

					&:before {
						background: $default-danger;
					}
				}
			}	
		}
	}

	&.wizard-gradient-primary {
		.nav-item {
			> .nav-link {
				&.active {
					&:after {
						background: $default-white; 
						border: 3px solid $gradient-primary-base-right;
					}
	
					&:before {
						background: $gradient-primary;
					}
				}
	
				&.completed {
					&:after {
						background: $gradient-primary;
					}	

					&:before {
						background: $gradient-primary;
					}
				}
			}	
		}
	}

	&.wizard-gradient-primary {
		.nav-item {
			> .nav-link {
				&.active {
					&:after {
						background: $default-white; 
						border: 3px solid $gradient-primary-base-right;
					}
	
					&:before {
						background: $gradient-primary;
					}
				}
	
				&.completed {
					&:after {
						background: $gradient-primary;
					}	

					&:before {
						background: $gradient-primary;
					}
				}
			}	
		}
	}

	&.wizard-gradient-success {
		.nav-item {
			> .nav-link {
				&.active {
					&:after {
						background: $default-white; 
						border: 3px solid $gradient-success-base-right;
					}
	
					&:before {
						background: $gradient-success;
					}
				}
	
				&.completed {
					&:after {
						background: $gradient-success;
					}	

					&:before {
						background: $gradient-success;
					}
				}
			}	
		}
	}

	&.wizard-gradient-info {
		.nav-item {
			> .nav-link {
				&.active {
					&:after {
						background: $default-white; 
						border: 3px solid $gradient-info-base-right;
					}
	
					&:before {
						background: $gradient-info;
					}
				}
	
				&.completed {
					&:after {
						background: $gradient-info;
					}	

					&:before {
						background: $gradient-info;
					}
				}
			}	
		}
	}

	&.wizard-gradient-warning {
		.nav-item {
			> .nav-link {
				&.active {
					&:after {
						background: $default-white; 
						border: 3px solid $gradient-warning-base-right;
					}
	
					&:before {
						background: $gradient-warning;
					}
				}
	
				&.completed {
					&:after {
						background: $gradient-warning;
					}	

					&:before {
						background: $gradient-warning;
					}
				}
			}	
		}
	}

	&.wizard-gradient-danger {
		.nav-item {
			> .nav-link {
				&.active {
					&:after {
						background: $default-white; 
						border: 3px solid $gradient-danger-base-right;
					}
	
					&:before {
						background: $gradient-danger;
					}
				}
	
				&.completed {
					&:after {
						background: $gradient-danger;
					}	

					&:before {
						background: $gradient-danger;
					}
				}
			}	
		}
	}
}
