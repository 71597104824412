/*----------------------------------------
  39. Nouislider
----------------------------------------*/

.noUi-handle {
	background-color: $default-white;
	border-radius: 50%;
	box-shadow: none;
	cursor: pointer;
	@include transition(all 0.2s ease); 

	&:active {
	    	@include transform(scale(1.4));
	}

    &:before,
    &:after {
    	display: none;	
    }
}

.noUi-horizontal {
	height: 6px;

	.noUi-handle {
	    left: -10px;
	    top: -7px;
	    width: 18px;
		height: 18px;
		border: 1px solid darken($border-color, 5%);
	}
}	


.noUi-vertical {
	height: 100%;
	width: 6px;
	display: inline-block;

	.noUi-handle {
		width: 18px;
		height: 18px;
		border: 1px solid darken($border-color, 5%);
	}

	.noUi-origin {
	    border-radius: 0px;
	    bottom: 0px;
	}
}

.noUi-background {
    background: $default-gray;
    box-shadow: none;
}

.noUi-origin {
    border-radius: 0px;
    bottom: 5px;
}

.noUi-target {
	border: 1px solid $border-color;
	box-shadow: none;
	background-color: #ececec;

	&.noUi-connect {
		box-shadow: none;
	}
}


.slider-primary {

	.noUi-connect {
		background-color: $default-primary;	
	}
}

.slider-success {

	.noUi-connect {
		background-color: $default-success;	
	}
}

.slider-info {

	.noUi-connect {
		background-color: $default-info;	
	}
}

.slider-warning {

	.noUi-connect {
		background-color: $default-warning;	
	}
}

.slider-danger {

	.noUi-connect {
		background-color: $default-danger;	
	}
}

.slider-gradient-primary {

	.noUi-connect {
		background: $gradient-primary;	
	}
}

.slider-gradient-success {

	.noUi-connect {
		background: $gradient-success;	
	}
}

.slider-gradient-info {

	.noUi-connect {
		background: $gradient-info;	
	}
}

.slider-gradient-warning {

	.noUi-connect {
		background: $gradient-warning;	
	}
}

.slider-gradient-danger {

	.noUi-connect {
		background: $gradient-danger;	
	}
}

.noUi-marker-large,
.noUi-marker-sub  {
	background: $border-color;
 }

.noUi-pips {
	color: $default-text-color;	
}

.noUi-value-sub {
	color: $default-text-color;	
	margin-top: 5px;
	font-size: 11px
}

.noUi-value {
	margin-top: 5px;
    font-size: 12px;
}
.noUi-marker-horizontal.noUi-marker-large {
	height: 10px;
}