/*----------------------------------------
  18. Page Title
----------------------------------------*/

.page-title {

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	span {
		font-size: 20px;
		margin-top: 15px;
		margin-bottom: 25px;
		margin-top: 0px;
	}
}