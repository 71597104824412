/*----------------------------------------
  47. Full Calendar
----------------------------------------*/

.fc {
	background-color: $default-white;

	th {
		text-align: center;
	    padding: 15px;
	    background-color: transparent;
	    color: $default-text-color;
	    font-size: 12px;
	    text-transform: uppercase;
	    border-right-width: 0;
	    border-left-width: 0;
	}

	button {
		background-color: $default-white;
		background-image: none;
		height: 37px;
		padding: 0 15px;
		color: darken($default-text-color,10%);

		&.fc-state-default {
			border-color: $border-color;
			box-shadow: none;
		}

		&.fc-state-active {
			box-shadow: none;
			background-color: $border-color;
		}
	}
}

.fc-toolbar {
	padding: 20px;
	border-top: 1px solid $border-color;
	border-right: 1px solid $border-color;
	border-left: 1px solid $border-color;

	&.fc-header-toolbar {
		margin-bottom: 0px;
	}
}

.fc-view,
.fc-view>table {
	background-color: $default-white;
	z-index: 0;
}


.fc-basic-view  {

	.fc-day-number,
	.fc-week-number {
		padding: 5px;
		margin: 0px 10px;
		margin-top: 5px;
	}

	.fc-today {
		.fc-day-number {
			background: $default-success;
			color: $default-white; 
			border-radius: 50%;
			padding: 0px 7px;
			line-height: 2.2;
		}
	}
}

.fc-unthemed {

	.fc-content, 
	.fc-divider, 
	.fc-popover, 
	.fc-row, 
	tbody, 
	td, 
	th, 
	thead {
		border-color: $border-color;
	}

	td {
		&.fc-today {
			background-color: transparent;
		}
	}
}

.fc-event-container {
	.fc-event {
		border-radius: 0px;
		border: 0px;
		background-color: lighten($default-success, 40%);
		color: $default-success !important;
		font-size: 12px;
   		line-height: 2.5;
		padding: 0px 15px;
		border-left: 3px solid $default-success;   
	}

	.fc-day-grid-event {
		margin: 1px 5px 5px;
	}
}


.calendar-event {

	.card-body {
		position: relative;
	}

	.add-event {
		position: absolute;
	    top: -22px;
	    z-index: 9;
	    right: 25px;
	    border: 0px;
	    font-size: 20px;
	    padding: 7px 12px;
	    border-radius: 50%;
	}

	.event-list {
		padding-left: 0;
    	list-style: none;

		.event-items {
			margin-bottom: 20px;
			position: relative;

			> a {
				display: block;
				color: $default-text-color;

				&:hover {
					color: darken($default-text-color, 25%);
				}
			}

			.remove {
				position: absolute;
				right: 0px;
				top: 12px;
				font-size: 20px;
				@include transition(all 0.3s ease-in-out); 
			}

			

			.event-name {
				font-weight: bold;
				border: 0px;
				padding: 0px 10px;
			}

			.event-detail {
				padding-left: 25px;
			}

			.bullet {
				width: 10px;
				height: 10px;
				display: inline-block;
				border-radius: 50%;
				background-color:  $default-white;
				border: 3px solid;
    			border-color: $default-gray;

				&.success {
					border-color:  $default-success;
				}

				&.warning {
					border-color:  $default-warning;
				}

				&.danger {
					border-color:  $default-danger;
				}
			}
		}
	}
}
