/*----------------------------------------
  6. Header
----------------------------------------*/

.header {
	display: block;
    height: $header-height;
	width: 100%;
	position: fixed;
    padding: 0px;
    z-index: 1040;
    background-color: $default-white;
	border-bottom: 1px solid $border-color;
	margin-bottom: 0px;
	@include transition(all 0.2s ease); 
	@include print {
		display: none;
	}

	@include screen-tablet {
		width: 100%;
	}

	.header-container {
		@include clearfix;
		
		.nav-logo {
			padding: 0px 20px;
			line-height: 0;
			float: left;
			width: $offscreen-size;
			@include transition(all 0.2s ease); 

			> a {
    			display: inline-block;
    			max-width: 200px;
    			width: 100%;
				
				.logo {
					background-repeat: no-repeat;
					background-position: center left;
				    display: inline-block;
				    width: 100%;
				    min-height: calc(#{$header-height} - 1px); 

				    &.logo-white {
				    	display: none;
				    }
				}
			}

			@include screen-tablet {
				width: 0px;
				padding: 0px;
			}	
		}

		.nav-left,
		.nav-right {
			position: relative;
			list-style: none;
			padding-left: 0;
			margin-bottom: 0px;
			
			>li {
				float: left;
				
				>a {
					padding: 0 12px;
					line-height: calc(#{$header-height} - 3px);
					min-height: calc(#{$header-height} - 3px);
					color: $default-text-color;
					display: block;
					@include transition(all 0.2s ease-in-out); 
					
					i {
						font-size: 14px;
					}
					
					&:hover,
					&:focus {
						text-decoration: none;
						color: $default-dark;
					}

					&.dropdown-toggle {
						&:after {
							display: none;
							border-radius: 0px;
						}
					}

					@include screen-tablet {
						padding: 0 10px;
					}

					@include screen-mobile-above {
						&.sidenav-fold-toggler {
							display: block;
						}

						&.sidenav-expand-toggler {
							display: none;
						}
					}

					@include screen-mobile {
						&.sidenav-fold-toggler {
							display: none;
						}

						&.sidenav-expand-toggler {
							display: block;
						}
					}
				}
			}
			
			.user-profile {

				@include screen-tablet {
					border-right: 0px;
					border-left: 0px;
				}	
				
				.profile-img {
					width: 35px;
					border-radius: 50%;
					margin-top: 12px;
					float: left;

					@include screen-tablet {
						width: 30px;
						margin-right: 0px;
					}
				}
			}
			
			.notifications {
				position: relative;

				.counter {
					position: absolute;
					right: 6px;
    				top: 12px;
					background-color: $default-danger;
					color: $default-white;
					padding: 3px 5.5px;
					border-radius: 50px;
					line-height: 1;
					font-size: 10px;
				}
			}
		}
		
		.nav-left {
			float: left;
		}
		
		.nav-right {
			float: right;

			@include screen-mobile {
				.dropdown.dropdown-animated.scale-left .dropdown-menu {
					right: -40px !important;
				}
			}
		}

		.search-box {
			
			.search-icon-close {
				display: none;
			}
	
			&.active {
				.search-icon {
					display: none;
				}
	
				.search-icon-close {
					display: inline-block;
				}
			}
		}
	
		.search-input {
			display: none;
	
			&.active {
				display: inline-block;
			}
	
			input {
				border: 0px;
				box-shadow: none;
				background-color: transparent;
				outline: none;
				height: 40px;
				margin-top: 10px;
				padding: 5px;
				font-size: 16px;
	
				@include screen-mobile {
					width: 85px;
				}
				
				@include placeholder {
					@include alpha-attribute('color', rgba($default-white, 0.5), white);
				}
			}
			
			.search-predict {
				display: none;
				position: absolute;
				top: $header-height;
				width: 350px;
				min-height: 50px;
				max-height: 500px;
				background-color: $default-white;
				color: $default-text-color;
				border-radius: 4px;
				@include box-shadow( 0px, 0px, 8px, 0px, rgba(0, 0, 0, 0.2));
	
				@include screen-mobile {
					left: 40px;
				}
				
				&.active {
					display: block;
				}
				
				.search-wrapper {
					position: relative;
					max-height: 400px;
					overflow-y: auto;
				}
			}
			
			.search-footer {
				border-top: 1px solid $border-color;
				text-align: center;
				padding: 15px;
				border-bottom-left-radius: 4px;
				border-bottom-right-radius: 4px;
			}
		}
	}
}
