/*----------------------------------------
  45. Chartist
----------------------------------------*/

.ct-chart {
	min-height: 280px;

	svg {
		min-height: 280px;
	}
}

.ct-grid {
    stroke: $border-color;
    stroke-width: 1px;
	stroke-dasharray: 0px;
}	

.ct-line {
    stroke-width: 3px;
}

.ct-point {
    stroke-width: 7px;
}

.text-white {
	.ct-label {
		fill: $default-white;
	}
}

.ct-series-a {
	.ct-bar,
	.ct-line,
	.ct-point,
	.ct-slice-donut {
		stroke: $default-success;
	}

	.ct-area,
	.ct-slice-donut-solid,
	.ct-slice-pie {
		fill: $default-success;
	}
}

.ct-series-b {
	.ct-bar,
	.ct-line,
	.ct-point,
	.ct-slice-donut {
		stroke: $default-info;
	}

	.ct-area,
	.ct-slice-donut-solid,
	.ct-slice-pie {
		fill: $default-info;
	}
}

.ct-series-c {
	.ct-bar,
	.ct-line,
	.ct-point,
	.ct-slice-donut {
		stroke: $default-warning;
	}

	.ct-area,
	.ct-slice-donut-solid,
	.ct-slice-pie {
		fill: $default-warning;
	}
}

.ct-series-d {
	.ct-bar,
	.ct-line,
	.ct-point,
	.ct-slice-donut {
		stroke: $default-primary;
	}

	.ct-area,
	.ct-slice-donut-solid,
	.ct-slice-pie {
		fill: $default-primary;
	}
}

.ct-series-e {
	.ct-bar,
	.ct-line,
	.ct-point,
	.ct-slice-donut {
		stroke: $default-danger;
	}

	.ct-area,
	.ct-slice-donut-solid,
	.ct-slice-pie {
		fill: $default-danger;
	}
}

.line-hadow {
	-webkit-filter: drop-shadow( 0px 20px 6px rgba(0,0,0,0.3) );
	filter: drop-shadow( 0px 20px 6px rgba(0,0,0,0.3) ); 
}