/*----------------------------------------
  32. Input
----------------------------------------*/

.form-group {
	margin-bottom: 25px;

	label {
		&.control-label {
			display: inline-block;
			margin-bottom: .5rem;
			font-size: 13px;
			font-weight: 500;
			text-transform: uppercase;
		}

		&.col-form-label {
			margin-bottom: 0;
			line-height: 1.5;
			padding-top: calc(.75rem + 1px);
			padding-bottom: calc(.75rem + 1px);
		}
	}
}

.form-control {
	border: 1px solid darken($border-color, 2%);
	border-radius: 5px;
	box-shadow: none;
	line-height: 2.2;
	font-size: 14px;
	color: $default-text-color;
	@include transition(all 0.2s ease-in); 
	@include placeholder {
		color: lighten($default-text-color, 20%);
	}

	&:focus {
		outline: 0 none;
		@include box-shadow(1px, 1px, 15px, rgba(0, 0, 0, 0.075)); 
		border-color: $default-success;	
	}	

	&.error {
		border-color: $default-danger;
	}

	&:disabled, 
	&[readonly] {
		background-color:  lighten($border-color, 4%);

		&:focus {
			border-color: $border-color;
		}
	}

	&:disabled {
		cursor: not-allowed;
	}
}

.form-control-plaintext {
	padding-top: 0.375rem;
	padding-bottom: 0.375rem;
	line-height: 2.2;
}

.form-text {
	margin-top: 5px;
	color: lighten($default-text-color, 5%)
}

.form-control-sm,
.input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
	padding: 0.15rem 0.75rem;
}

.form-control-lg, 
.input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
	padding: 0.7rem 0.75rem;
}

.icon-input {
	position: relative;

	> .form-control {
		padding-left: 50px;
		padding-right: 50px;
	}

	> i {
		position: absolute;
		top: 0px;
		left: 15px;
		font-size: 20px;
		padding-top: 10px;
		line-height: 1;
		color: lighten($default-text-color, 20%);
	}
}

.input-group {
	
	> .input-group-prepend,
	> .input-group-append {

		> .btn {
			margin: 0px;
		}

		&.dropdown {
			display: flex;
		}
	}
}

.input-group-text {
	background-color: lighten($border-color, 4%);
	border: 1px solid darken($border-color, 2%);
	color: lighten($default-text-color, 10%);

	.checkbox {
		padding: 0px;

		input[type=checkbox] {
			+label {
				
				&:before {
					margin-right: 0px;
				}	
			}	
		}
	}

	.radio {
		padding: 0px;

		input[type=radio] {
			+label {
				
				&:before {
					margin-right: 0px;
				}	
			}	
		}
	}
}

.textarea-md {
	min-height: 100px;
}

.textarea-md {
	min-height: 150px;
}

.textarea-lg {
	min-height: 250px;
}