.theme-configurator {
	position: relative;	
}

.theme-selector {
	
	label {
		position: relative;
		display: inline-block;
		width: 25px;
		height: 25px;
		margin-right: 5px;
		margin-bottom: 5px;
		border-radius: 50%;
		cursor: pointer;
	}

	input {
		opacity: 0;

		&:checked {
			~ .theme-color {

				&:after {
					content: "\e64c";
					font-family: themify;
					font-size: 12px;
					font-weight: bold;
					display: block;
					color: $default-white;
					width: 100%;
					text-align: center;
					position: absolute;
					top: 50%;
					@include transform(translateY(-50%));
				}

				&.bg-white {
					&:after {
						color: $default-dark;
					}
				}
			}
		}
	}

	.theme-color {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		border-radius: 50%;
	}
}

.color-pallet {
	border: 1px solid $border-color;
	min-width: 180px;
	margin-bottom: 20px;

	.box {
		height: 120px;
	}

	.desc {
		padding: 10px;
	}
}